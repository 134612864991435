import { Button, Container, Typography } from 'Components/Base';
import Box from 'Components/Base/Box';
import Loading from 'Components/Loading';
import { useGetChantImageSubscriber } from 'Domain/OrderChantImage/hooks';
import ImageBox from '../Components/OrderDetails/Components/ImageBox';
import { useConfig } from 'Libs/Config';

import { THEME_VARIANT } from 'Components/Base/Theme/themeConfigs';

interface OrderChantConfirmProps {
  id: string;
  orderId: string;
  onEditChantMessage?: () => void;
  onConfirmChantMessage?: () => void;
}

export default function OrderChantConfirm(props: OrderChantConfirmProps) {
  const {
    id,
    orderId,
    onEditChantMessage = () => {
      //
    },
    onConfirmChantMessage = () => {
      //
    },
  } = props;

  const config = useConfig();
  const { isExisted, data } = useGetChantImageSubscriber(id);
  const chantImageUrl = data?.url;

  console.log('chantimage', { isExisted, data });

  return (
    <Box
      my={2}
      sx={
        config.theme.variant === THEME_VARIANT.THAIRATH
          ? (theme) => ({
              position: 'relative',
              backgroundColor: theme.palette.common.white,
              padding: theme.spacing(3),
              borderRadius: theme.spacing(3),
            })
          : { position: 'relative' }
      }
    >
      {config.theme.variant === THEME_VARIANT.THAIRATH && (
        <Typography
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            mb: 2,
            textAlign: 'center',
          })}
        >
          บทสวดของคุณ
        </Typography>
      )}

      {!isExisted && !chantImageUrl && <Loading />}
      {isExisted && chantImageUrl && <ImageBox imageUrl={chantImageUrl} hideDownloadButton />}
      <Box display="grid" gridTemplateColumns="repeat(2, minmax(auto, 268px))" gap={3} mt={2}>
        <Button variant="outlined" color="primary" size="large" onClick={onEditChantMessage}>
          แก้ไขข้อความ
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          stType="gradient"
          onClick={onConfirmChantMessage}
          disabled={!chantImageUrl}
        >
          ยืนยันบทสวด
        </Button>
      </Box>
    </Box>
  );
}
