import React from 'react';
import { Field, useForm } from 'react-final-form';
import { FormControl, Select, MenuItem, TextField, FormHelperText } from '@mui/material';

import { Box, Typography } from 'Components/Base';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import ChantMessageInstructions from 'Components/Locations/ChantMessageInstructions';
import ImageUploadBox from 'Containers/ImageUploadBox';

import { availableDays, availableMonths, availableYears } from '../utils/utils';
import { UPLOAD_MODE } from 'Containers/ImageUploadBox/ImageUploadBox';

export default function ChantForm({
  orderRealId,
  FORM_TEXT,
  locationId,
  mode,
  showBirthdayInput = false,
  showFacePictureInput = false,
}: {
  orderRealId: string;
  FORM_TEXT: any;
  locationId: string;
  mode: CHANT_MODE;
  showBirthdayInput?: boolean;
  showFacePictureInput?: boolean;
}) {
  const form = useForm();
  return (
    <>
      <Typography variant="info" mt={1}>
        {'* ข้อมูลของท่านจะถูกใช้เพื่อการขอพร / แก้บนเท่านั้น'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="chantName"
          render={(props) => (
            <TextField
              {...props.input}
              label="ชื่อ-นามสกุล"
              variant="outlined"
              placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 60 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : (
                  'Tips: กรุณากรอกชื่อจริงเพื่อใช้ในการทำพิธี'
                )
              }
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="chantMessage"
          render={(props) => (
            <TextField
              {...props.input}
              label={FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_CHANT_MESSAGE]}
              variant="outlined"
              fullWidth
              multiline
              placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_CHANT_MESSAGE]}
              rows={4}
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 300 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : (
                  'Tips: แบ่งวรรคให้ประโยคเพื่อความสวยงามของบทสวด'
                )
              }
            />
          )}
        />
      </FormControl>
      {showBirthdayInput && (
        <>
          <Box my={2}></Box>
          <FormControl fullWidth required>
            <Field
              name="birthday.day"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}
                  </Typography>
                  <Select
                    {...props.input}
                    displayEmpty
                    variant="outlined"
                    // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                    </MenuItem>
                    {availableDays?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthday.month"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}
                  </Typography>
                  <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                    </MenuItem>
                    {availableMonths?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthday.year"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}
                  </Typography>
                  <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                    </MenuItem>
                    {availableYears?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthTime"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}
                  </Typography>
                  <TextField
                    {...props.input}
                    label={''}
                    variant="outlined"
                    placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                    fullWidth
                    InputProps={{
                      startAdornment: <div />,
                    }}
                    inputProps={{ maxLength: 60 }}
                    helperText={
                      props.meta.error && props.meta.touched ? (
                        <FormHelperText error>{props.meta.error}</FormHelperText>
                      ) : null
                    }
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      {showFacePictureInput && (
        <>
          <Box my={3}></Box>
          <FormControl fullWidth>
            <Field
              name="facePicture"
              render={(props) => {
                return (
                  <>
                    <Typography variant="h5">{`รูปถ่ายหน้าตรง สำหรับใช้ทำพิธี`}</Typography>
                    <Typography variant="info">
                      {'* รูปเป็นแนวตั้งและเห็นเต็มหน้าแบบชัดเจน (เห็นหน้าผาก)'}
                    </Typography>
                    <ImageUploadBox
                      value={props.input.value}
                      uploadActionText="อัพโหลดรูป"
                      uploadPrefix="client-order-uploads"
                      uploadKey={`client/${locationId}/${orderRealId}`}
                      onUploadFiles={(data) => {
                        form.change('facePicture', JSON.stringify(data));
                        form.blur('facePicture');
                      }}
                      mode={UPLOAD_MODE.IMAGE}
                    />
                  </>
                );
              }}
            />
          </FormControl>
        </>
      )}
      <Box my={5}></Box>
      <ChantMessageInstructions locationId={locationId} mode={mode} />
    </>
  );
}
