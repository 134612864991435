import { useGetChantImageSubscriber } from 'Domain/OrderChantImage/hooks';
import { CHANT_MODE } from 'Domain/Sattha/Chant';
import { useGetStoreOrderUploadSubscriber } from 'Domain/StoreOrderUpload/hooks';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import OrderDetailsUI from '../Components/OrderDetails';
import { OrderShippingDataProps } from '../Components/OrderDetails/Components/ShippingDetailsBox';

interface OrderDetailsContainerProps {
  id: string;
  orderId: string;
  status: ORDER_STATUS;
  locationId: string;
  mode: CHANT_MODE;
  locationHolyThingImageUrl?: string;
  haveShippingData?: boolean;
  shippingData?: OrderShippingDataProps;
  shippingTrackingData?: any;
  orderInfo: SatthaOrder;
}

const canShowStoreUpload = (status: ORDER_STATUS) =>
  [
    ORDER_STATUS.CLIENT_ACCEPT,
    ORDER_STATUS.COMPLETED,
    ORDER_STATUS.MESSAGE_FAIL_DELIVERING,
  ].includes(status);

export default function OrderDetails(props: OrderDetailsContainerProps) {
  const {
    orderId,
    id,
    status,
    locationId,
    mode,
    locationHolyThingImageUrl,
    haveShippingData,
    shippingData,
    shippingTrackingData,
    orderInfo,
  } = props;
  const { isExisted, data } = useGetChantImageSubscriber(id);
  const { isExisted: isStoreOrderUploadExisted, data: storeOrderUploadData } =
    useGetStoreOrderUploadSubscriber(id);
  console.log('chantimage', { isExisted, data });
  console.log('storeOrderUpload', { isStoreOrderUploadExisted, storeOrderUploadData });
  const storeUploadImageList = storeOrderUploadData?.attachmentList;
  const showStoreUpload = canShowStoreUpload(status);
  return (
    <OrderDetailsUI
      isChantImageLoading={!isExisted}
      chantImageUrl={data?.url}
      storeUploadImageList={showStoreUpload ? storeUploadImageList : []}
      showFeedbackButton={showStoreUpload}
      locationId={locationId}
      locationHolyThingImageUrl={locationHolyThingImageUrl}
      mode={mode}
      haveShippingData={haveShippingData}
      shippingData={shippingData}
      shippingTrackingData={shippingTrackingData}
      orderInfo={orderInfo}
    />
  );
}
