import { useState } from 'react';
import PropTypes from 'prop-types';

import { ConfigContext } from './context';
import getConfig from './utils';

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(getConfig());
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

ConfigProvider.propTypes = {
  config: PropTypes.object,
};

export default ConfigProvider;
