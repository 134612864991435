// import { Divider } from '@mui/material';
// import GetAppIcon from '@mui/icons-material/GetApp';

import { Box, Typography } from 'Components/Base';

import ImageBox from './ImageBox';

type ChantImageBoxProps = {
  imageUrl: string;
};

export default function ChantImageBox({ imageUrl }: ChantImageBoxProps) {
  return (
    <Box display="grid" gap={2}>
      <Typography variant="h4">บทสวดของคุณ</Typography>
      <ImageBox imageUrl={imageUrl} />
    </Box>
  );
}
