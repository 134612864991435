import { Box, Typography } from 'Components/Base';

const OrderInfoDetails = ({ label, value }: { label: string; value?: string }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: '120px 1fr' }}
      columnGap={2}
    >
      <Typography sx={{ fontWeight: 650, color: '#49454F', fontSize: { xs: 12, sm: 14, md: 16 } }}>
        {label}
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          fontWeight: 400,
          color: theme.palette.primary.main,
          fontSize: { xs: 12, sm: 14, md: 16 },
          wordBreak: 'break-word',
        })}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default OrderInfoDetails;
