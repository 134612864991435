import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Typography } from 'Components/Base';

const StateTitle = ({ title }: { title: string }) => {
  const theme = useTheme();
  const isMinWidthSM = useMediaQuery(theme.breakpoints.up('sm'));

  if (!title) return null;

  return (
    <Box sx={{ position: 'relative', maxWidth: 600, margin: '0 auto', mb: 4 }}>
      <img
        src="/assets/icon-star.png"
        alt="star icon"
        style={{ position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)' }}
      />
      <Typography
        variant={isMinWidthSM ? 'h2' : 'h3'}
        sx={(theme) => ({
          textAlign: 'center',
          color: theme.palette.common.white,
        })}
      >
        {title}
      </Typography>
      <img
        src="/assets/icon-star.png"
        alt="star icon"
        style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}
      />
    </Box>
  );
};

export default StateTitle;
