import { satthaTheme, thairathTheme, THEME_VARIANT } from './themeConfigs';

const themeVariant = process.env.REACT_APP_THEME_CONFIG_VARIANT || 'SATTHA';

let themeConfig;

switch (themeVariant) {
  case THEME_VARIANT.THAIRATH:
    themeConfig = thairathTheme;
    break;
  default:
    themeConfig = satthaTheme;
}

export default themeConfig;
